<template>
	<div class="editor" v-if="file && file.name">
		<input
			type="text"
			class="editor__control editor__control--filename"
			v-model="file.name"
		/>
		<div class="editor__content">
			<textarea
				v-if="file.content"
				class="editor__control editor__control--content"
				v-auto-size
				v-model="file.content"
			/>
			<div class="editor__buttons">
				<button
					v-if="isMarkdown"
					class="editor__button button"
					@click="state.showPreview = !state.showPreview"
				>
					{{
						state.showPreview ? "Hide preview" : "Show preview"
					}}</button
				><button
					class="button button--primary"
					@click="saveFile"
					:disabled="!isUpdated"
				>
					Save
				</button>
				<button
					class="button button--primary"
					:disabled="!isUpdated"
					@click="saveAndPublish"
				>
					Save &amp; Publish File
				</button>
			</div>
			<div class="editor__preview" v-if="state.showPreview">
				<button
					v-if="isMarkdown"
					class="editor__button button"
					@click="state.showPreview = !state.showPreview"
				>
					{{ state.showPreview ? "Hide preview" : "Show preview" }}
				</button>
				<div
					class="editor__preview-content"
					v-html="preview.document"
				></div>
			</div>
			<EditorMeta v-if="settings" :meta="settings" />

			<!-- <p>State</p>
			<pre>{{ file }}</pre>
			<p>loaded file</p>
			<pre>{{ ogFile }}</pre> -->
		</div>
	</div>
</template>

<script lang="ts">
import {
	defineComponent,
	computed,
	reactive,
	watch,
	watchEffect,
	onMounted,
	ref
} from "vue";
import { useStore } from "../store";
import router from "../router";
import EditorMeta from "./EditorMeta.vue";

import { File, FileActions, FileGetters } from "../types";
import { MarkdownRender, MarkdownMetaRender } from "../services/markdown";

export default defineComponent({
	components: {
		EditorMeta
	},
	setup() {
		const store = useStore();
		const state = reactive({
			showPreview: false,
			isUpdating: false,
			file: {
				id: "",
				name: "",
				type: "",
				content: "",
				parent: "",
				og: 0,
				path: "",
				sha: ""
			}
		});

		// const file = ref();

		const getFile = (): File =>
			store.getters[`files/${FileGetters.GET_FILE_BY_PATH}`](
				router.currentRoute.value.params.path
			);

		const setFields = (payload: File): void => {
			if (payload && payload.id) {
				state.file.id = payload.id || "";
				state.file.name = payload.name || "";
				state.file.type = payload.type || "";
				state.file.parent = payload.parent || "";
				state.file.content = payload.content || "";
				state.file.path = payload.path || "";
				state.file.sha = payload.sha || "";
			}
		};

		const file = ref(getFile());
		const isMarkdown = computed(() => state.file.name.indexOf(".md") > 0);
		const isUpdated = computed(
			() =>
				file.value.content !== state.file.content ||
				file.value.name !== state.file.name
		);

		watchEffect(() => {
			if (!isMarkdown.value) state.showPreview = false;
			file.value = getFile();
			setFields(file.value);
		});

		onMounted(() => {
			const path = router.currentRoute.value.params.path;
			if (path) {
				setFields(getFile());
			}
		});

		const saveFile = () => {
			store.dispatch(`files/${FileActions.SAVE_FILE}`, state.file);
			setTimeout(() => setFields(getFile()), 1000);
		};

		const settings = computed(
			() =>
				state.file.content &&
				isMarkdown.value &&
				MarkdownMetaRender(state.file.content)
		);

		const preview = computed(
			() =>
				state.showPreview &&
				state.file.content &&
				isMarkdown.value &&
				MarkdownRender(state.file.content)
		);

		const saveAndPublish = () => {
			saveFile();
			store.dispatch(`files/${FileActions.PUBLISH_FILE}`, state.file);
		};

		return {
			state,
			file: state.file,
			ogFile: file,
			settings,
			preview,
			saveAndPublish,
			saveFile,
			isUpdated,
			isMarkdown
		};
	}
});
</script>
<style lang="scss">
.editor {
	display: flex;
	flex-direction: column;
	max-width: 960px;
	margin: auto;
	&__control {
		border: none;
		border-radius: var(--border-radius);
		padding: 1em;
		background-color: var(--background-accent);
		color: var(--text);
		& + .editor__content {
			margin-top: 1em;
		}
		&:focus {
			outline: none;
			border: none;
			box-shadow: 0 0 0 1px var(--primary);
		}
		&--filename {
			font-size: 1.25em;
			background-color: transparent;
		}
		&--content {
			min-height: 40vh;
			max-height: 80vh;
			resize: none;
		}
	}
	&__content {
		display: flex;
		flex-direction: column;
	}
	&__preview {
		width: 100%;
		button {
			display: none;
		}
		@media screen and (max-width: 720px) {
			z-index: 20;
			position: fixed;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: calc(100% - var(--space));
			height: calc(100% - var(--space));
			background-color: var(--background);

			border-radius: var(--border-radius);
			box-shadow: 0 0 1em 0 black;
			button {
				display: block;
				position: absolute;
				bottom: var(--space);
				left: 50%;
				transform: translateX(-50%);
			}
		}
	}
	&__preview-content {
		height: 100%;
		overflow: scroll;
		width: 100%;
		padding: var(--space);
	}
	&__buttons {
		width: auto;
		padding: var(--space) 0;
		display: flex;
		justify-content: space-between;
	}
	.button {
		font-size: 1rem;
	}
}
</style>