<template>
	<div class="loader"></div>
</template>

<script lang="ts"></script>

<style lang="scss">
.loader {
	--loader-duration: 3s;
	--loader-color: var(--text);
	--loader-color2: var(--primary);
	--loader-stroke: 0.3em;
	position: relative;
	animation: rotateLoader var(--loader-duration, 1s) ease-in-out infinite;
	&,
	&::before,
	&::after {
		width: 5em;
		height: 5em;
		border-radius: 50%;
	}
	&::before,
	&::after {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		content: "";
		display: block;

		border: var(--loader-stroke) solid var(--loader-color);
		border-bottom-color: transparent;
		border-left-color: transparent;
	}
	&::after {
		opacity: 0.5;
		border-top-color: var(--loader-color2);
		border-right-color: var(--loader-color2);
		animation: rotateLoaderAfter var(--loader-duration, 1s)
			calc(var(--loader-duration, 1s) / 8) ease-in-out infinite;
	}
}
@keyframes rotateLoader {
	from {
		transform: rotate(0turn);
	}
	to {
		transform: rotate(1turn);
	}
}
@keyframes rotateLoaderAfter {
	from {
		transform: translate(-50%, -50%) rotate(0turn);
	}
	to {
		transform: translate(-50%, -50%) rotate(1turn);
	}
}
</style>