<template>
	<div class="meta">
		<dl
			class="meta__list"
			v-for="(key, idx) in Object.keys(meta)"
			:key="idx"
		>
			<dt class="meta__key">{{ key }}</dt>
			<dd class="meta__value">
				<span
					class="meta__item"
					v-for="(item, idy) in prettyValue(key)"
					:key="idy"
					>{{ item }}</span
				>
			</dd>
		</dl>
	</div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { Meta } from "../types";

export default defineComponent({
	props: {
		meta: {
			type: Object as PropType<Meta>,
			default: {}
		}
	},
	setup(props) {
		const prettyValue = (key: string): string[] => {
			const value: unknown = props.meta[key];

			switch (typeof value) {
				case "string":
					return [value];
				case "number":
					return [value.toString()];
				case "boolean":
					return value ? ["true"] : ["false"];
				case "object":
					return value ? Object.values(value) : [];
				default:
					return [];
			}
		};
		return { prettyValue };
	}
});
</script>

<style lang="scss">
.meta {
	&__list {
		& + & {
			border-top: 1px solid var(--background-accent);
		}

		padding: 0.5em;
		display: flex;
	}
	&__item {
		border-radius: var(--border-radius);
		display: inline-block;
		padding: 0.5em;
		background-color: var(--background-accent);
		& + & {
			margin-left: 0.5em;
		}
	}
	&__key {
		width: 25%;
	}
	&__value {
		width: 75%;
		display: flex;
	}
}
</style>