
import { defineComponent, onMounted } from "vue";

import FileExplorer from "../components/FileExplorer.vue";
import Editor from "../components/Editor.vue";

import { useStore } from "../store";
import { getFiles, publishAll } from "../services/git";
import { FileActions } from "../types";

export default defineComponent({
	name: "Home",
	components: { FileExplorer, Editor },
	setup() {
		const store = useStore();

		onMounted(async () => {
			await getFiles().then((result) => {
				store.dispatch(`files/${FileActions.SET_FILES}`, result);
			});
		});

		return {
			publishAll
		};
	}
});
