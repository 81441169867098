<template>
	<component
		class="file__link"
		:is="file.type == 'blob' ? 'a' : 'span'"
		:class="[current === file.path ? 'file__link--current' : '']"
	>
		<span class="file__name">{{ file.name }}</span>
	</component>
</template>

<script lang="ts">
import { defineComponent, PropType, computed } from "vue";
import router from "../router";

import { File } from "../types";
export default defineComponent({
	props: {
		file: {
			type: Object as PropType<File>
		}
	},
	setup() {
		const current = computed(
			() => router.currentRoute.value.params.path || null
		);
		return {
			current
		};
	}
});
</script>