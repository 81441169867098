
import {
	defineComponent,
	computed,
	reactive,
	watch,
	watchEffect,
	onMounted,
	ref
} from "vue";
import { useStore } from "../store";
import router from "../router";
import EditorMeta from "./EditorMeta.vue";

import { File, FileActions, FileGetters } from "../types";
import { MarkdownRender, MarkdownMetaRender } from "../services/markdown";

export default defineComponent({
	components: {
		EditorMeta
	},
	setup() {
		const store = useStore();
		const state = reactive({
			showPreview: false,
			isUpdating: false,
			file: {
				id: "",
				name: "",
				type: "",
				content: "",
				parent: "",
				og: 0,
				path: "",
				sha: ""
			}
		});

		// const file = ref();

		const getFile = (): File =>
			store.getters[`files/${FileGetters.GET_FILE_BY_PATH}`](
				router.currentRoute.value.params.path
			);

		const setFields = (payload: File): void => {
			if (payload && payload.id) {
				state.file.id = payload.id || "";
				state.file.name = payload.name || "";
				state.file.type = payload.type || "";
				state.file.parent = payload.parent || "";
				state.file.content = payload.content || "";
				state.file.path = payload.path || "";
				state.file.sha = payload.sha || "";
			}
		};

		const file = ref(getFile());
		const isMarkdown = computed(() => state.file.name.indexOf(".md") > 0);
		const isUpdated = computed(
			() =>
				file.value.content !== state.file.content ||
				file.value.name !== state.file.name
		);

		watchEffect(() => {
			if (!isMarkdown.value) state.showPreview = false;
			file.value = getFile();
			setFields(file.value);
		});

		onMounted(() => {
			const path = router.currentRoute.value.params.path;
			if (path) {
				setFields(getFile());
			}
		});

		const saveFile = () => {
			store.dispatch(`files/${FileActions.SAVE_FILE}`, state.file);
			setTimeout(() => setFields(getFile()), 1000);
		};

		const settings = computed(
			() =>
				state.file.content &&
				isMarkdown.value &&
				MarkdownMetaRender(state.file.content)
		);

		const preview = computed(
			() =>
				state.showPreview &&
				state.file.content &&
				isMarkdown.value &&
				MarkdownRender(state.file.content)
		);

		const saveAndPublish = () => {
			saveFile();
			store.dispatch(`files/${FileActions.PUBLISH_FILE}`, state.file);
		};

		return {
			state,
			file: state.file,
			ogFile: file,
			settings,
			preview,
			saveAndPublish,
			saveFile,
			isUpdated,
			isMarkdown
		};
	}
});
