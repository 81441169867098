
import { defineComponent, PropType, computed } from "vue";
import router from "../router";

import { File } from "../types";
export default defineComponent({
	props: {
		file: {
			type: Object as PropType<File>
		}
	},
	setup() {
		const current = computed(
			() => router.currentRoute.value.params.path || null
		);
		return {
			current
		};
	}
});
