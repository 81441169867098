import MarkdownIt from "markdown-it";
import emoji from "markdown-it-emoji";
import prism from "markdown-it-prism";
import anchor from "markdown-it-anchor";
import tasks from "markdown-it-tasks";
import { extractMeta, removeMeta } from "./markdown-meta";

import { MarkdownData } from "../types";

const md = new MarkdownIt({
	html: true,
	linkify: true,
	typographer: true,
	breaks: true
});

md.use(prism);
md.use(emoji);
md.use(anchor);
md.use(tasks, { enabled: true, label: true, labelAfter: true });

export const MarkdownRender = (input: string): MarkdownData => {
	const metaData = extractMeta(input);
	const renderedDocument = md.render(removeMeta(input));

	return { document: renderedDocument, meta: metaData };
};

export const MarkdownMetaRender = (input: string): MarkdownData => {
	const metaData = extractMeta(input);
	return metaData;
};
