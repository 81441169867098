<template>
	<section class="file">
		<aside class="file__explorer">
			<FileExplorer />
			<button @click="publishAll">Publish all</button>
		</aside>
		<main class="file__view">
			<Editor />
		</main>
	</section>
</template>

<script lang="ts">
import { defineComponent, onMounted } from "vue";

import FileExplorer from "../components/FileExplorer.vue";
import Editor from "../components/Editor.vue";

import { useStore } from "../store";
import { getFiles, publishAll } from "../services/git";
import { FileActions } from "../types";

export default defineComponent({
	name: "Home",
	components: { FileExplorer, Editor },
	setup() {
		const store = useStore();

		onMounted(async () => {
			await getFiles().then((result) => {
				store.dispatch(`files/${FileActions.SET_FILES}`, result);
			});
		});

		return {
			publishAll
		};
	}
});
</script>

<style lang="scss">
.file {
	display: flex;
	@media screen and (max-width: 720px) {
		flex-direction: column-reverse;
	}
	&__explorer {
		padding: var(--space);
	}
	&__view {
		width: 100%;
		padding: var(--space);
		@media screen and (min-width: 720px) {
			padding-left: 0;
		}
	}
}
</style>