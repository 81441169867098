
import { defineComponent, reactive, computed, watchEffect } from "vue";
import { useStore } from "../store";
import FileLink from "./FileLink.vue";
import FileItem from "./FileItem.vue";
import Loader from "./Loader.vue";
import router from "../router";
import { File, FileGetters } from "../types";

export default defineComponent({
	components: {
		Loader,
		FileItem,
		FileLink
	},
	setup() {
		const store = useStore();
		const state = reactive({ loading: true });
		const files = computed(
			(): File[] => store.getters[`files/${FileGetters.GET_FILES}`]
		);

		watchEffect(() => {
			state.loading = files.value.length > 0;
		});

		const getChildren = (parent = "") =>
			files.value.filter((file) => file.parent == parent);

		const hasChildren = (id: string) =>
			files.value.some((file) => file.parent == id);

		const goTo = (path: string) =>
			router.push({ name: "FileView", params: { path: path } });

		return {
			goTo,
			getChildren,
			hasChildren
		};
	}
});
