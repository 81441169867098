
import { defineComponent, PropType } from "vue";
import { File } from "../types";

export default defineComponent({
	props: {
		file: {
			type: Object as PropType<File>
		}
	}
});
