<template>
	<div class="files">
		<Loader v-if="loading"></Loader>
		<ul v-else class="file__list">
			<FileItem
				:file="file1"
				v-for="(file1, idx1) in getChildren('')"
				:key="idx1"
			>
				<FileLink :file="file1" @click="goTo(file1.path)" />
				<ul class="file__list" v-if="hasChildren(file1.id)">
					<FileItem
						:file="file2"
						v-for="(file2, idx2) in getChildren(file1.id)"
						:key="idx2"
					>
						<FileLink :file="file2" @click="goTo(file2.path)" />
						<ul class="file__list" v-if="hasChildren(file2.id)">
							<FileItem
								:file="file3"
								v-for="(file3, idx3) in getChildren(file2.id)"
								:key="idx3"
							>
								<FileLink
									:file="file3"
									@click="goTo(file3.path)"
								/>
							</FileItem>
						</ul>
					</FileItem>
				</ul>
			</FileItem>
		</ul>
	</div>
</template>

<script lang="ts">
import { defineComponent, reactive, computed, watchEffect } from "vue";
import { useStore } from "../store";
import FileLink from "./FileLink.vue";
import FileItem from "./FileItem.vue";
import Loader from "./Loader.vue";
import router from "../router";
import { File, FileGetters } from "../types";

export default defineComponent({
	components: {
		Loader,
		FileItem,
		FileLink
	},
	setup() {
		const store = useStore();
		const state = reactive({ loading: true });
		const files = computed(
			(): File[] => store.getters[`files/${FileGetters.GET_FILES}`]
		);

		watchEffect(() => {
			state.loading = files.value.length > 0;
		});

		const getChildren = (parent = "") =>
			files.value.filter((file) => file.parent == parent);

		const hasChildren = (id: string) =>
			files.value.some((file) => file.parent == id);

		const goTo = (path: string) =>
			router.push({ name: "FileView", params: { path: path } });

		return {
			goTo,
			getChildren,
			hasChildren
		};
	}
});
</script>

<style lang="scss">
@mixin icon {
	content: "";
	box-shadow: 0 0 0 0.1em currentColor inset;
	border-radius: 0.1em;
	display: block;
	width: 1em;
	height: 1em;
	background-color: var(--icon-background, --background);
	margin-right: 0.5em;
}
@mixin file {
	@include icon;
	clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 0.3em, 0.4em 0%);
	width: 0.75em;
	background-image: linear-gradient(
		to left bottom,
		currentColor,
		currentColor
	);
	background-size: 50% 33.33%;
	background-position: top right;
	background-repeat: no-repeat;

	color: var(--icon-color, var(--secondary));
}
@mixin folder {
	@include icon;
	flex-shrink: 0;
	clip-path: polygon(
		0 0,
		0 100%,
		100% 100%,
		100% 0.15em,
		0.6em 0.15em,
		0.6em 0%
	);

	height: 0.85em;
	background-image: linear-gradient(to bottom, currentColor, currentColor);
	background-size: 0.5em 0.25em;
	background-repeat: no-repeat;
	background-position: top right;
	color: var(--icon-color, var(--primary));
}
.files {
	padding: calc(var(--space) / 2);
	border-radius: calc(var(--border-radius) * 2);
	// position: absolute;
	background-color: black;
	--background-accent: white;
	& > .file__list {
		padding-left: 0;
		& > .file__item {
			background-size: 0 0;
		}
	}
}

.file {
	&__list {
		position: relative;
		padding-left: calc(var(--space) / 2);
	}
	&__icon {
		// display: inline-block;
	}
	&__item {
		position: relative;
		&--tree {
			.file__link::before {
				@include folder;
			}
		}
		&--file {
			.file__link::before {
				@include file;
			}
		}
		&--updated {
			&::before {
				content: "";
				display: block;
				background-color: var(--warning);
				width: 0.33em;
				height: 0.33em;
				border-radius: 50%;
				position: absolute;
				left: 0;
				top: 50%;
				transform: translate(-50%, -50%);
				z-index: 2;
			}
		}
	}
	&__link {
		opacity: 0.8;
		display: flex;
		padding: 0.5em 1em;
		cursor: pointer;
		&:hover {
			opacity: 1;
		}
		&--current {
			--icon-color: var(--background);
			--icon-background: var(--primary);
			background-color: var(--primary);
			border-radius: var(--border-radius);
			color: var(--background);
		}
	}
	&__name {
		font-size: 0.875em;
	}
}
</style>