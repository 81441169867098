
import { defineComponent, PropType } from "vue";
import { Meta } from "../types";

export default defineComponent({
	props: {
		meta: {
			type: Object as PropType<Meta>,
			default: {}
		}
	},
	setup(props) {
		const prettyValue = (key: string): string[] => {
			const value: unknown = props.meta[key];

			switch (typeof value) {
				case "string":
					return [value];
				case "number":
					return [value.toString()];
				case "boolean":
					return value ? ["true"] : ["false"];
				case "object":
					return value ? Object.values(value) : [];
				default:
					return [];
			}
		};
		return { prettyValue };
	}
});
