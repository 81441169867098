<template>
	<li
		class="file__item"
		:class="[
			`file__item--${file.type}`,
			file.updated ? `file__item--updated` : ``
		]"
	>
		<slot></slot>
	</li>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { File } from "../types";

export default defineComponent({
	props: {
		file: {
			type: Object as PropType<File>
		}
	}
});
</script>